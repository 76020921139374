import React, {Component} from 'react';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Card} from "primereact/card";
import {Calendar} from "primereact/components/calendar/Calendar";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Growl} from "primereact/growl";
import {accepted, dateToString, initialPage, isEmpty, loadProfile, removeDuplicates} from "../../util/commonFunctions";
import {Checkbox} from "primereact/checkbox";
import {InputMask} from "primereact/inputmask";


export class Profile extends Component {

    static defaultProps = {
        openedInKYC: null,
        nextStep: null
    }

    constructor(props) {
        super();
        this.state = {
            showSsn: false,
            firstName: '',
            middleName: '',
            lastName: '',
            birthday: '',
            preferredCurrency: '',
            phoneNumber: '',
            phoneType: '',
            governmentIssuedIdType: '',
            governmentIssuedIdNumber: '',
            governmentIssuedIdExpirationDate: '',
            governmentIssuedIdCountryOfIssuance: 'US',
            business: '',
            taxID: '',
            ssn: '',

            country: null,
            filteredCountries: null,
            countryBirth: '',
            nationality: '',
            filteredNationalities: null,
            addressLine1: '',
            addressLine2: '',
            city: '',
            _state: '',
            postCode: '',

            billingAddress:{},
            shippingAddress: {
                country: null,
                filteredCountries: null,
                countryBirth: '',
                nationality: '',
                filteredNationalities: null,
                addressLine1: '',
                addressLine2: '',
                city: '',
                _state: '',
                postCode: '',
            },
            billing_shipping_same:true,

            catPhones: [],
            catCountries: [],
            catNationalities: [],
            catIdTypes: [],
            catStates: [],
            currencies:[{label: 'Select Currency', value: ''},
                {label: 'USD', value: 'USD'}],
            errors: {},
            email:''
        };
        this.backendService = new BackendService();
        this.changeHandler = this.changeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.searchCountry = this.searchCountry.bind(this);
        this.searchNationality= this.searchNationality.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.loadCatalogs = this.loadCatalogs.bind(this);
        this.changeHandlerShowSsn = this.changeHandlerShowSsn.bind(this);
    }
    componentDidMount() {
        let countryProfile="", countryBirthProfile="",nationalityProfile="", governmentIssuedIdCountryOfIssuance='';
        loadProfile(this, this.backendService, null).then(resp => {
            if (resp !== null) {
                const profile = resp;
                const dataProfile = profile.profile;
                const bday = dataProfile.bday !== null ? new Date(dataProfile.bday) : null;
                const issuedIdExpirationDate = dataProfile.government_issued_id_expiration_date !== null ?
                    new Date(dataProfile.government_issued_id_expiration_date) : null;

                countryProfile=dataProfile.country;
                countryBirthProfile=dataProfile.country_birth;
                nationalityProfile=dataProfile.nationality;
                governmentIssuedIdCountryOfIssuance = dataProfile.government_issued_id_country_of_issuance;

                if(isEmpty(countryProfile)) {
                    countryProfile = "US";
                }
                if(isEmpty(countryBirthProfile)){
                    countryBirthProfile = "US"
                }
                if(isEmpty(governmentIssuedIdCountryOfIssuance)){
                    governmentIssuedIdCountryOfIssuance = "US";
                }
                if(isEmpty(nationalityProfile)){
                    nationalityProfile='US'
                }
                this.findStates(countryProfile)
                    .then(result => {
                        let stateCountry = result.find(obj => obj.name === dataProfile.billing_state);
                        console.log(stateCountry)

                        this.setState({
                            firstName: profile.first_name !== null ? profile.first_name : '',
                            ssn: profile.profile.ssn !== null ? profile.profile.ssn : '',
                            middleName: dataProfile.middle_name !== null ? dataProfile.middle_name : '',
                            lastName: profile.last_name !== null ? profile.last_name : '',
                            birthday: bday,
                            preferredCurrency: dataProfile.preferred_currency !== null ? dataProfile.preferred_currency : '',
                            phoneNumber: dataProfile.phone_number !== null ? dataProfile.phone_number : '',
                            phoneType: dataProfile.phone_type !== null ? dataProfile.phone_type : '',
                            governmentIssuedIdType: dataProfile.government_issued_id_type !== null ? dataProfile.government_issued_id_type : '',
                            governmentIssuedIdNumber: dataProfile.government_issued_id_number !== null ? dataProfile.government_issued_id_number : '',
                            governmentIssuedIdExpirationDate: issuedIdExpirationDate,
                            addressLine1: dataProfile.billing_address_line_1 !== null ? dataProfile.billing_address_line_1 : '',
                            addressLine2: dataProfile.billing_address_line_2 !== null ? dataProfile.billing_address_line_2 : '',
                            city: dataProfile.billing_city !== null ? dataProfile.billing_city : '',
                            _state: dataProfile.billing_state !== null ? stateCountry : '',
                            postCode: dataProfile.billing_post_code !== null ? dataProfile.billing_post_code : '',
                            email: profile.email,
                            businessName: profile.businessName,
                            taxID: dataProfile.tax_id,
                        });

                        if (nationalityProfile === 'US') {
                            this.setState({showSsn: true})
                        } else {
                            this.setState({showSsn: false})
                        }
                        this.loadCatalogs(countryProfile, countryBirthProfile, nationalityProfile, governmentIssuedIdCountryOfIssuance);// Aquí tu código posterior...
                    })
                    .catch(error => {
                        console.error("Error", error);
                    });
            }
        });

    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    loadCatalogs(countryProfile, countryBirthProfile, nationalityProfile, governmentIssuedIdCountryOfIssuance){
        const {
            shippingAddress
        } = this.state;

        this.backendService.getCatalogs().then(resp => {
            if (resp.status === 'success') {
                const catPhones = [];
                //const catStates = [];
                const catCountries = [];
                let catNationalities = [];
                const catIdTypes = [];

                catPhones.push({label: "Select Phone Type", value: ''});
                catIdTypes.push({label: "Select Issues Id Type", value: ''});
                //catStates.push({label: "Select State", value: ''});

                resp.catPhones.forEach(element => catPhones.push({label: element[1], value: element[0]}));
                //resp.catStates.forEach(element => catStates.push({label: element[1], value: element[0]}));
                resp.catCountries.forEach(element => catCountries.push({label: element[1], value: element[0]}));
                resp.catNationalities.forEach(element => catNationalities.push({label: element[1], value: element[0]}));
                resp.catIdTypes.forEach(element => catIdTypes.push({label: element[1], value: element[0]}));

                catNationalities = removeDuplicates(catNationalities, "label");

                const country = countryProfile;//catCountries.find(data => data.value === countryProfile);
                const countryBirth = countryBirthProfile; //catCountries.find(data => data.value === countryBirthProfile);
                const nationality = nationalityProfile; //catNationalities.find(data => data.value === nationalityProfile);
                const gICountryIssuance = governmentIssuedIdCountryOfIssuance; //catCountries.find(data => data.value === governmentIssuedIdCountryOfIssuance);

                //country = country === undefined ? {label: "United States of America", value: "US"} : country;

                shippingAddress.country=country;
                this.setState({
                    catPhones,
                    catCountries,
                    catNationalities,
                    catIdTypes,
                    country,
                    countryBirth,
                    nationality,
                    governmentIssuedIdCountryOfIssuance: gICountryIssuance,

                    shippingAddress
                });
            }
        }).catch(error => {
            console.log("error: ", error);
            this.growl.show({severity: 'error', summary: 'Error', detail: "Error load catalogs"});
        });
    }

    submitHandler = e => {
        e.preventDefault();
        if (this.state.showSsn === true) {
            this.setState({ssn: this.state.ssn})
        } else {
            this.setState({ssn: null})
        }
        const {
            firstName,
            middleName,
            lastName,
            birthday,
            preferredCurrency,
            phoneNumber,
            phoneType,
            governmentIssuedIdType,
            governmentIssuedIdNumber,
            governmentIssuedIdExpirationDate,
            governmentIssuedIdCountryOfIssuance,
            businessName,
            taxID,
            ssn,
            country,
            countryBirth,
            nationality,
            addressLine1,
            addressLine2,
            city,
            _state,
            postCode,

            billing_shipping_same,
            shippingAddress,
        } = this.state;
        const params = {
            first_name: firstName,
            ssn: ssn,
            middle_name: middleName,
            last_name: lastName,
            bday: dateToString(birthday) ,
            preferred_currency: preferredCurrency,
            phone_number: phoneNumber,
            phone_type: phoneType,
            government_issued_id_type: governmentIssuedIdType,
            government_issued_id_number: governmentIssuedIdNumber,
            government_issued_id_expiration_date: dateToString(governmentIssuedIdExpirationDate),
            government_issued_id_country_of_issuance: governmentIssuedIdCountryOfIssuance,
            country: country,
            country_birth: countryBirth,
            nationality: nationality,
            billing_address_line_1: addressLine1,
            billing_address_line_2: addressLine2,
            billing_city: city,
            billing_state: _state.name,
            billing_post_code: postCode,
            shippingAddress,
            businessName,
            taxID,
        };
        const errors = this.validateForm(params, billing_shipping_same);
        if(Object.keys(errors).length === 0) {
            this.backendService.postUpdateProfile(params).then(resp => {
                if (resp.status === 'success') {
                    const documents = JSON.parse(localStorage.getItem('documents'));
                    documents.profile = accepted;
                    localStorage.setItem('documents', JSON.stringify(documents));
                    this.growl.show({severity: 'success', summary: 'SUCCESS'});
                    loadProfile(this, this.backendService, null).then(resp => {
                        if (resp !== null) {
                            if(this.props.openedInKYC === 'openedInKYC'){
                                this.props.nextStep();
                            } else {
                                this.props.history.push(initialPage);
                            }
                        }
                    });
                } else {
                    console.log('Error in Service: ', resp);
                    this.growl.show({severity: 'error', summary: 'Error', detail: "Error in Service:"});
                }
            }).catch(error => {
                if (error.response.data.msg != null) {
                    let errors = '';
                    for (const prop in error.response.data.msg) {
                        errors += prop + ', \n';
                    }
                    this.growl.show({severity: 'error', summary: 'Error', detail: "Error in Service: " + errors});
                } else {
                    this.growl.show({severity: 'error', summary: 'Error', detail: "Error in Service"});
                    console.log('Fields are required: ', error);
                }
            });
        } else {
            this.setState({errors});
        }

    };
    changeHandler = event => {
        const {errors} = this.state;
        if(errors[event.target.name] !== undefined){
            errors[event.target.name] = undefined;
        }
        if (event.target.name === 'phoneNumber' && event.target.value.length > 14) {
            return
        }
        if (event.target.name === 'country') {
            const value = event.target.value
            this.findStates(value)
        }
        this.setState({[event.target.name]: event.target.value, errors});
    };

    findStates = (value) => {
        this.setState({chargedYet: false});
        const params = {
            code_countri: value
        };
        return this.backendService.postFindStates(params).then(resp => {
            if (resp.status === "success") {
                this.setState({chargedYet: true, catStates: resp.data});
                return resp.data;
            } else {
                this.setState({chargedYet: true});
                this.growl.show({
                    severity: 'error',
                    summary: 'Error in service',
                    detail: resp.data.msg,
                    life: 7000
                });
                throw new Error(`Error in service: ${resp.data.msg}`);
            }
        }).catch(error => {
            this.setState({chargedYet: true});
            console.error("Error", error);
            throw error;
        });
    }

    changeHandlerShowSsn = event => {
        const {errors} = this.state;
        if(errors[event.target.name] !== undefined){
            errors[event.target.name] = undefined;
        }
        if (event.target.value === 'US') {
            this.setState({
                showSsn: true
            });
        } else {
            this.setState({showSsn: false})
        }
        this.setState({[event.target.name]: event.target.value, errors});
    };
    changeHandlerShipping = event => {
        const {errors,shippingAddress} = this.state;
        if(errors.shippingAddress?.[event.target.name] !== undefined){
            errors.shippingAddress[event.target.name] = undefined;
        }
        shippingAddress[event.target.name] = event.target.value;
        this.setState({shippingAddress, errors});
    };

    searchCountry(event) {
        let filteredCountries;
        if (!event.query.trim().length) {
            filteredCountries = [...this.state.catCountries];
            this.setState({filteredCountries});
        } else {
            setTimeout(() => {
                filteredCountries = this.state.catCountries.filter((country) => {
                    return country.label.toLowerCase().startsWith(event.query.toLowerCase());
                });
                this.setState({filteredCountries});
            }, 250);
        }
    }
    searchNationality(event) {
        let filteredNationalities;
        if (!event.query.trim().length) {
            filteredNationalities = [...this.state.catNationalities];
            this.setState({filteredNationalities});
        } else {
            setTimeout(() => {
                filteredNationalities = this.state.catNationalities.filter((country) => {
                    return country.label.toLowerCase().startsWith(event.query.toLowerCase());
                });
                this.setState({filteredNationalities});
            }, 250);
        }
    }
    itemTemplate(item) {
        return (
            <div className="country-item">
                <div>{item.label}</div>
            </div>
        );
    }

    validateForm = (data, billing_shipping_same) => {
        let errors = {};
        if (!data.first_name) errors.firstName = 'First name name is mandatory.';
        if (this.state.showSsn === true) {
            if (!data.ssn) errors.ssn = 'SSN name  is mandatory.';
        };
        if (!data.last_name)  errors.lastName = 'Last name name is mandatory.';
        if (!data.bday)  errors.birthday = 'Birthday name is mandatory.';
        if (!data.preferred_currency)  errors.preferredCurrency = 'Preferred currency name is mandatory.';
        if (!data.phone_number)  errors.phoneNumber = 'Phone number name is mandatory.';
        if (!data.phone_type)  errors.phoneType = 'Phone type name is mandatory.';
        if (!data.government_issued_id_type)  errors.governmentIssuedIdType = 'Government issues id type name is mandatory.';
        if (!data.government_issued_id_number)  errors.governmentIssuedIdNumber = 'Government issues id number name is mandatory.';
        if (!data.government_issued_id_expiration_date)  errors.governmentIssuedIdExpirationDate = 'Government issues id expiration date name is mandatory.';
        if (!data.government_issued_id_country_of_issuance)  errors.governmentIssuedIdCountryOfIssuance = 'Government issues id country of issuance name is mandatory.';
        if (!data.country)  errors.country = 'Country name is mandatory.';
        if (!data.country_birth)  errors.countryBirth = 'Country birth name is mandatory.';
        if (!data.nationality)  errors.nationality = 'Nationality name is mandatory.';
        if (!data.billing_address_line_1)  errors.addressLine1 = 'Address 1 name is mandatory.';
        //if (!data.billing_address_line_2)  errors.addressLine2 = 'Address 2 name is mandatory.';
        if (!data.billing_city)  errors.city = 'City name is mandatory.';
        if (!data.billing_state)  errors._state = 'State name is mandatory.';
        if (!data.billing_post_code)  errors.postCode = 'Post code name is mandatory.';

        if(!billing_shipping_same){
            errors.shippingAddress = {};
            if (!data.shippingAddress.country) errors.shippingAddress.country = 'Country name is mandatory.';
            //if (!data.shippingAddress.country_birth)  errors.shippingAddress.countryBirth = 'Country birth name is mandatory.';
            //if (!data.shippingAddress.nationality)  errors.shippingAddress.nationality = 'Nationality name is mandatory.';
            if (!data.shippingAddress.addressLine1)  errors.shippingAddress.addressLine1 = 'Address 1 name is mandatory.';
            //if (!data.shippingAddress.billing_address_line_2)  errors.shippingAddress.addressLine2 = 'Address 2 name is mandatory.';
            if (!data.shippingAddress.city)  errors.shippingAddress.city = 'City name is mandatory.';
            if (!data.shippingAddress._state)  errors.shippingAddress._state = 'State name is mandatory.';
            if (!data.shippingAddress.postCode)  errors.shippingAddress.postCode = 'Post code name is mandatory.';

            if(Object.keys(errors.shippingAddress).length === 0) {
                delete errors.shippingAddress;
            }
        }
        return errors;
    };

    render() {
        const {
            firstName,
            middleName,
            lastName,
            birthday,
            preferredCurrency,
            phoneNumber,
            phoneType,
            governmentIssuedIdType,
            governmentIssuedIdNumber,
            governmentIssuedIdExpirationDate,
            governmentIssuedIdCountryOfIssuance,
            //businessName,
            //taxID,
            ssn,
            country,
            countryBirth,
            nationality,
            addressLine1,
            addressLine2,
            city,
            _state,
            postCode,
            shippingAddress,
            catPhones,
            catStates,
            catIdTypes,
            errors,
        } = this.state;

        const bAddress = (
            <div className="p-col-12">
                <div className="p-grid">
                    <div className="p-col-12 mt-20">
                        <h2>
                            Main Address
                        </h2>
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>Country</label>
                        <Dropdown options={this.state.catCountries}
                                  tooltip= "Country"
                                  name="country"
                                  value={country}
                                  onChange={this.changeHandler}
                                  disabled={false}
                                  autoWidth={false} />
                        {/*
                            <AutoComplete
                                value={country}
                                suggestions={this.state.filteredCountries}
                                completeMethod={this.searchCountry}
                                name="country" inputClassName="pd-9"
                                field="label" dropdown
                                itemTemplate={this.itemTemplate}
                                onChange={this.changeHandler}
                                disabled={true}
                            />
                        */}
                        {errors?.country !== undefined ?
                            <small className="p-messages p-messages-error">{errors?.country}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>Street address 1</label>
                        <InputText
                            tooltip= "Enter Street address 1"
                            className="pd-11"
                            placeholder="Address Line 1"
                            name="addressLine1"
                            onChange={this.changeHandler}
                            value={addressLine1}
                        />
                        {errors?.addressLine1 !== undefined ?
                            <small className="p-messages p-messages-error">{errors?.addressLine1}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>Street address 2</label>
                        <InputText
                            tooltip= "Enter Street address 2"
                            className="pd-11"
                            placeholder="Address Line 2"
                            name="addressLine2"
                            onChange={this.changeHandler}
                            value={addressLine2}
                        />
                        {errors?.addressLine2 !== undefined ?
                            <small className="p-messages p-messages-error">{errors?.addressLine2}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>City</label>
                        <InputText
                            tooltip= "Enter City"
                            className="pd-11"
                            placeholder="City"
                            name="city"
                            onChange={this.changeHandler}
                            value={city}
                        />
                        {errors?.city !== undefined ?
                            <small className="p-messages p-messages-error">{errors?.city}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>Province</label>
                        <Dropdown options={catStates}
                                  tooltip= "Enter State"
                                  name="_state"
                                  value={_state}
                                  onChange={this.changeHandler}
                                  autoWidth={false}
                                  optionLabel="name"
                        />
                        {errors?._state !== undefined ?
                            <small className="p-messages p-messages-error">{errors?._state}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>Postal/Zip Code</label>
                        <InputText
                            tooltip= "Enter Postal/Zip Code"
                            className="pd-11"
                            placeholder="Postal/Zip Code"
                            name="postCode"
                            onChange={this.changeHandler}
                            value={postCode}
                        />
                        {errors?.postCode !== undefined ?
                            <small className="p-messages p-messages-error">{errors?.postCode}</small>
                            : ""
                        }
                    </div>
                </div>
            </div>
        );

        const sAddress = (
            <div className="p-col-12">
                <div className="p-grid">
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>Country</label>
                        <Dropdown options={this.state.catCountries}
                                  tooltip= "Country"
                                  name="country"
                                  value={shippingAddress.country}
                                  onChange={this.changeHandlerShipping}
                                  disabled={false}
                                  autoWidth={false} />
                        {/*
                            <AutoComplete
                                value={shippingAddress.country}
                                suggestions={this.state.filteredCountries}
                                completeMethod={this.searchCountry}
                                name="country" inputClassName="pd-9"
                                field="label" dropdown
                                itemTemplate={this.itemTemplate}
                                onChange={this.changeHandlerShipping}
                                disabled={true}
                            />
                        */}

                        {errors?.shippingAddress?.country !== undefined ?
                            <small className="p-messages p-messages-error">{errors?.shippingAddress?.country}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>Street address 1 </label>
                        <InputText
                            tooltip= "Enter Street address 1"
                            className="pd-11"
                            placeholder="Address Line 1"
                            name="addressLine1"
                            onChange={this.changeHandlerShipping}
                            value={shippingAddress.addressLine1}
                        />
                        {errors?.shippingAddress?.addressLine1 !== undefined ?
                            <small className="p-messages p-messages-error">{errors?.shippingAddress?.addressLine1}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>Street address 2</label>
                        <InputText
                            tooltip= "Enter Street address 2"
                            className="pd-11"
                            placeholder="Address Line 2"
                            name="addressLine2"
                            onChange={this.changeHandlerShipping}
                            value={shippingAddress.addressLine2}
                        />
                        {errors?.shippingAddress?.addressLine2 !== undefined ?
                            <small className="p-messages p-messages-error">{errors?.shippingAddress?.addressLine2}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>City</label>
                        <InputText
                            tooltip= "Enter City"
                            className="pd-11"
                            placeholder="City"
                            name="city"
                            onChange={this.changeHandlerShipping}
                            value={shippingAddress.city}
                        />
                        {errors?.shippingAddress?.city !== undefined ?
                            <small className="p-messages p-messages-error">{errors?.shippingAddress?.city}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>State</label>
                        <Dropdown options={catStates}
                                  tooltip= "Enter State"
                                  name="_state"
                                  value={shippingAddress._state}
                                  onChange={this.changeHandlerShipping}
                                  autoWidth={false} />
                        {errors?._state !== undefined ?
                            <small className="p-messages p-messages-error">{errors?.shippingAddress?._state}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>Postal/Zip Code</label>
                        <InputText
                            tooltip= "Enter Postal/Zip Code"
                            className="pd-11"
                            placeholder="Postal/Zip Code"
                            name="postCode"
                            onChange={this.changeHandlerShipping}
                            value={shippingAddress.postCode}
                        />
                        {errors?.shippingAddress?.postCode !== undefined ?
                            <small className="p-messages p-messages-error">{errors?.shippingAddress?.postCode}</small>
                            : ""
                        }
                    </div>
                </div>
            </div>
        );

        const address = (
            <div className="p-grid">
                {bAddress}
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 mt-20">
                            <h2>
                                Shipping Address
                                <Checkbox inputId="binary" className="padd-left-15"
                                          checked={this.state.billing_shipping_same}
                                          onChange={e => this.setState({billing_shipping_same: e.checked})}/>
                                {this.state.billing_shipping_same && <small style={{color: "#E7E8ED"}}> Same Billing Address</small>}
                            </h2>
                        </div>
                    </div>
                </div>
                {!this.state.billing_shipping_same && sAddress}
            </div>
        );

        return (
            <div className="p-grid p-fluid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <Card>
                    <div className="p-grid p-fluid">
                        <div className="card card-w-title">
                            <div className="p-fluid p-grid p-formgrid">
                                <div className="p-col-12 p-md-12">
                                    <h2>Profile</h2>
                                </div>
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>First Name</label>
                                    <InputText
                                        tooltip= "Enter First Name"
                                        className="pd-11"
                                        placeholder="First Name"
                                        name="firstName"
                                        onChange={this.changeHandler}
                                        value={firstName}
                                    />
                                    {errors?.firstName !== undefined ?
                                        <small className="p-messages p-messages-error">{errors?.firstName}</small>
                                        : ""
                                    }
                                </div>
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Middle Name</label>
                                    <InputText
                                        tooltip= "Enter Middle Name"
                                        className="pd-11"
                                        placeholder="Middle Name"
                                        name="middleName"
                                        onChange={this.changeHandler}
                                        value={middleName}
                                    />
                                    {errors?.middleName !== undefined ?
                                        <small className="p-messages p-messages-error">{errors?.middleName}</small>
                                        : ""
                                    }
                                </div>
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Last Name</label>
                                    <InputText
                                        tooltip= "Enter Last Name"
                                        className="pd-11"
                                        placeholder="Last Name"
                                        name="lastName"
                                        onChange={this.changeHandler}
                                        value={lastName}
                                    />
                                    {errors?.lastName !== undefined ?
                                        <small className="p-messages p-messages-error">{errors?.lastName}</small>
                                        : ""
                                    }
                                </div>
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Email</label>
                                    <InputText
                                        tooltip= "Enter Email"
                                        tooltipOptions={{position: 'top'}}
                                        className="pd-11"
                                        placeholder="Email"
                                        value={this.state.email}
                                        readOnly={true}
                                        disabled={true}
                                    />
                                </div>
                                <div className="p-field p-col-12 p-sm-6 p-xl-4">
                                    <label htmlFor="mask">Birthday</label>
                                    <Calendar id="date"
                                              mask="99/99/9999"
                                              readOnlyInput
                                              tooltip= "Enter Birthday"
                                              inputClassName="pd-11"
                                              todayButtonClassName="pd-11"
                                              placeholder="birthday"
                                              dateFormat="dd/mm/yy"
                                              name="birthday"
                                              value={birthday}
                                              onChange={this.changeHandler}
                                              showIcon monthNavigator yearNavigator yearRange="1920:2015"/>
                                    {errors?.birthday !== undefined ?
                                        <small className="p-messages p-messages-error">{errors?.birthday}</small>
                                        : ""
                                    }
                                </div>
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Preferred Currency</label>
                                    <Dropdown options={this.state.currencies}
                                              tooltip= "Preferred Currency"
                                              value={preferredCurrency}
                                              name="preferredCurrency"
                                              onChange={this.changeHandler}
                                              autoWidth={false} />
                                    {errors?.preferredCurrency !== undefined ?
                                        <small className="p-messages p-messages-error">{errors?.preferredCurrency}</small>
                                        : ""
                                    }
                                </div>
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Phone</label>
                                    <InputText
                                        tooltip= "Enter Phone"
                                        tooltipOptions={{position: 'top'}}
                                        className="pd-11"
                                        placeholder="(999) 999-9999"
                                        name="phoneNumber"
                                        onChange={this.changeHandler}
                                        value={phoneNumber}
                                    />
                                    {errors?.phoneNumber !== undefined ?
                                        <small className="p-messages p-messages-error">{errors?.phoneNumber}</small>
                                        : ""
                                    }
                                </div>
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Phone Type</label>
                                    <Dropdown options={catPhones}
                                              tooltip= "Enter Phone Type"
                                              tooltipOptions={{position: 'top'}}
                                              value={phoneType}
                                              name="phoneType"
                                              onChange={this.changeHandler}
                                              autoWidth={false} />
                                    {errors?.phoneType !== undefined ?
                                        <small className="p-messages p-messages-error">{errors?.phoneType}</small>
                                        : ""
                                    }
                                </div>
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Government Issues Id Type</label>
                                    <Dropdown options={catIdTypes}
                                              tooltip= "Government Issues Id Type"
                                              tooltipOptions={{position: 'top'}}
                                              name="governmentIssuedIdType"
                                              value={governmentIssuedIdType}
                                              onChange={this.changeHandler}
                                              autoWidth={false} />
                                    {errors?.governmentIssuedIdType !== undefined ?
                                        <small className="p-messages p-messages-error">{errors?.governmentIssuedIdType}</small>
                                        : ""
                                    }
                                </div>
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Government Issues Id Number</label>
                                    <InputText
                                        tooltip= "Government Issues Id Number"
                                        tooltipOptions={{position: 'top'}}
                                        className="pd-11"
                                        placeholder="Government Issues Id Number"
                                        name="governmentIssuedIdNumber"
                                        onChange={this.changeHandler}
                                        value={governmentIssuedIdNumber}
                                    />
                                    {errors?.governmentIssuedIdNumber !== undefined ?
                                        <small className="p-messages p-messages-error">{errors?.governmentIssuedIdNumber}</small>
                                        : ""
                                    }
                                </div>
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Government Issues Id Expiration date</label>
                                    <Calendar id="GI_Expiration date"
                                              mask="99/99/9999"
                                              readOnlyInput
                                              tooltip= "Government Issues Id Expiration date"
                                              tooltipOptions={{position: 'top'}}
                                              inputClassName="pd-11"
                                              todayButtonClassName="pd-11"
                                              placeholder="Expiration date"
                                              dateFormat="dd/mm/yy"
                                              name="governmentIssuedIdExpirationDate"
                                              value={governmentIssuedIdExpirationDate}
                                              onChange={this.changeHandler}
                                              showIcon monthNavigator yearNavigator yearRange="1920:2050"/>
                                    {errors?.governmentIssuedIdExpirationDate !== undefined ?
                                        <small className="p-messages p-messages-error">{errors?.governmentIssuedIdExpirationDate}</small>
                                        : ""
                                    }
                                </div>
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Government issues ID country of Issuance</label>
                                    <Dropdown options={this.state.catCountries}
                                              tooltip= "Government issues ID country of Issuance"
                                              tooltipOptions={{position: 'top'}}
                                              name="governmentIssuedIdCountryOfIssuance"
                                              value={governmentIssuedIdCountryOfIssuance}
                                              onChange={this.changeHandler}
                                              disabled={false}
                                              autoWidth={false} />
                                    {/*
                                        <AutoComplete
                                            value={governmentIssuedIdCountryOfIssuance}
                                            suggestions={this.state.filteredCountries}
                                            completeMethod={this.searchCountry}
                                            name="governmentIssuedIdCountryOfIssuance" inputClassName="pd-9"
                                            field="label" dropdown
                                            itemTemplate={this.itemTemplate}
                                            onChange={this.changeHandler}
                                        />
                                    */}
                                    {errors?.governmentIssuedIdCountryOfIssuance !== undefined ?
                                        <small
                                            className="p-messages p-messages-error">{errors?.governmentIssuedIdCountryOfIssuance}</small>
                                        : ""
                                    }
                                </div>
                                {/*<div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Business Name</label>
                                    <InputText
                                        tooltip= "Enter Business Name"
                                        tooltipOptions={{position: 'top'}}
                                        className="pd-11"
                                        placeholder="Business Name"
                                        name="businessName"
                                        onChange={this.changeHandler}
                                        value={businessName}
                                    />
                                </div>
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Tax ID #</label>
                                    <InputMask
                                        tooltip= "Enter Tax ID #"
                                        tooltipOptions={{position: 'top'}}
                                        mask="99-9999999"
                                        className="pd-11"
                                        placeholder="99-9999999"
                                        name="taxID"
                                        onChange={this.changeHandler}
                                        value={taxID}
                                    />
                                </div>*/}
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Country Birth</label>
                                    <Dropdown options={this.state.catCountries}
                                              tooltip= "Country Birth"
                                              tooltipOptions={{position: 'top'}}
                                              name="countryBirth"
                                              value={countryBirth}
                                              onChange={this.changeHandler}
                                              disabled={false}
                                              autoWidth={false} />
                                    {/*
                                    <AutoComplete
                                        value={countryBirth}
                                        suggestions={this.state.filteredCountries}
                                        completeMethod={this.searchCountry}
                                        name="countryBirth" inputClassName="pd-9"
                                        field="label" dropdown
                                        itemTemplate={this.itemTemplate}
                                        onChange={this.changeHandler}/>
                                    */}

                                    {errors?.countryBirth !== undefined ?
                                        <small className="p-messages p-messages-error">{errors?.countryBirth}</small>
                                        : ""
                                    }
                                </div>
                                <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Nationality</label>
                                    <Dropdown options={this.state.catCountries}
                                              tooltip= "Nationality"
                                              tooltipOptions={{position: 'top'}}
                                              name="nationality"
                                              value={nationality}
                                              disabled={false}
                                              onChange={this.changeHandlerShowSsn}
                                              autoWidth={false}/>
                                    {errors?.nationality !== undefined ?
                                        <small className="p-messages p-messages-error">{errors?.nationality}</small>
                                        : ""
                                    }
                                </div>
                                {this.state.showSsn &&
                                    <div className="p-col-12 p-sm-6 p-xl-4">
                                    <label>Social Security Number</label>
                                    <InputMask
                                        tooltip="Enter Social Security Number"
                                        tooltipOptions={{position: 'top'}}
                                        mask="999-99-9999"
                                        className="pd-11"
                                        placeholder="999-99-9999"
                                        name="ssn"
                                        onChange={this.changeHandler}
                                        value={ssn}
                                    />
                                    {errors?.ssn !== undefined ?
                                        <small className="p-messages p-messages-error">{errors?.ssn}</small>
                                        : ""
                                    }
                                </div>
                                }

                                {address}
                                {this.props.openedInKYC === 'openedInKYC' &&
                                    <div className="p-col-12 p-md-4 p-md-offset-8 mt-40">
                                        <Button label='Next'
                                                style={{marginBottom: '10px'}} onClick={this.submitHandler}/>
                                    </div>
                                }
                                {this.props.openedInKYC !== 'openedInKYC' &&
                                    <div className="p-col-12 p-md-4 p-md-offset-4 mt-40">
                                        <Button label='Submit'
                                                style={{marginBottom: '10px'}} onClick={this.submitHandler}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}
